body,
img,
button,
h1,
h2,
h3,

p {
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
}

input {
    border: 1px solid #9E9E9E;
}

input:focus {
    border: 1px solid #6DC57E;
    outline: none;
}

a {
    text-decoration: none;
}

.bg-color-page-depan {
    background-color: #f5f4f1;
}

.igahp-h1-bold {
    font-family: "Inter";
    font-size: 32px;
    font-weight: 700;
    letter-spacing: -0.672px;
}

.igahp-h1-medium {
    font-family: "Inter";
    font-size: 32px;
    font-weight: 500;
    letter-spacing: -0.672px;
}

.igahp-h1-semi-bold {
    font-family: "Inter";
    font-size: 32px;
    font-weight: 600;
    letter-spacing: -0.672px;
}

.igahp-h2-bold {
    font-family: "Inter";
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -0.532px;
}

.igahp-h2-semi-bold {
    font-family: "Inter";
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.532px;
    line-height: 2.5rem;
}

.igahp-h3-semi-bold {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.456px;
}
.igahp-h3-medium {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.456px;
}

.igahp-highlight-bold {
    font-family: "Inter";
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.88px;
}

.igahp-hero-title-bold {
    font-family: "Inter";
    font-size: 68px;
    font-weight: 800;
    letter-spacing: -1.36px;
    line-height: 70px;
    text-transform: uppercase;
}

.igahp-highlight-semi-bold {
    font-family: "Inter";
    font-size: 40px;
    font-weight: 600;
    letter-spacing: -0.88px;
    line-height: 3.5rem;
}

.igahp-subheading-semi-bold {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.34px;
}

.igahp-subheading-medium {
    font-family: "Inter";
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.34px;
}

.igahp-l-medium {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.252px;
}

.igahp-l-semi-bold {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.252px;
}

.igahp-l-regular {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.252px;
}

.igahp-m-regular {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.176px;
}

.igahp-m-medium {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.176px;
}

.igahp-m-semi-bold {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.176px;
}

.igahp-s-regular {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.084px;
    /* line-height: 1em; */
}

.igahp-s-medium {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.084px;
}

.igahp-s-semibold {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -0.084px;
}

.igahp-xs-regular {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
}

.igahp-xs-medium {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
}

.igahp-xs-semibold {
    font-family: "Inter";
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.084px;
}

.igahp-xxs-regular {
    font-family: "Inter";
    font-size: 10px;
    font-weight: 400;
    letter-spacing: -0.084px;
}

.top-navbar {
    display: flex;
    background-color: #f5f5f5;
    padding: 0 16px;
    height: 44px;
    align-items: center;
}

.content-left {
    left: 0;
    display: flex;
    margin-left: 48px;
}

.content-right {
    right: 0;
    display: flex;
    margin-right: 48px;
    position: absolute;
}

.content-phone,
.content-email {
    display: flex;
    margin-left: 24px;
}

.phone-number,
.email {
    color: #9E9E9E;
    text-align: center;
    margin-left: 8px;
}

.content-linkedin {
    margin-left: 8px;
}

.white-text {
    color: #fff;
}

.blue-text {
    color: #1C5BB8;
}

.black-text {
    color: #121212;
}

.neutral-90-text {
    color: #404040;
}

.neutral-80-text {
    color: #616161;
}

.neutral-60-text {
    color: #9e9e9e;
}

.neutral-50-text {
    color: #c2c2c2;
}

.green-main-text {
    color: #19AF00;
}

.green-pressed-text {
    color: #083A00;
}

.centered-align-text {
    text-align: center;
}

footer {
    padding: 1.5rem;
    background: #fff;
}

footer .wrapper-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}

.wrapper-footer-menu ul {
    display: flex;
    gap: 2rem;
    padding: 0;
    margin-bottom: 1.5rem;
}

.wrapper-footer-menu li a {
    color: #000;
}

footer .container {
    display: grid;
    grid-template-columns: 6fr 4fr 4fr;
    gap: 24px;
}

.igahp-navbar {
    /* max-width: 1680px; */
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
    /* height: 70px; */
    display: flex;
    align-items: center;
    position: sticky;
    /* box-shadow: 0 2px 4px 0 rgba(99, 99, 99, 0.08); */
}

.igahp-navbar-content {
    position: sticky;
    width: 100%;
    top: 0;
    background: #FFF;
    box-shadow: 0 2px 4px 0 rgba(99, 99, 99, 0.08);
    z-index: 999;
}

.navbar-menu-expanded {
    padding: 1rem;
    display: none;
    position: absolute;
    background: #fff;
    width: 100%;
    float: top;
}

.igahp-navbar .igahp-navbar-left {
    margin-left: 1.25rem;
}

.igahp-navbar-left #logo-igahp {
    width: 100%;
}


.igahp-navbar-left {
    left: 0;
}

.igahp-navbar-right {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    padding: 6px;
    cursor: pointer;
}

ul {
    list-style: none;
}

.igahp-navbar-right ul {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-right: 2.5rem;
    margin-bottom: 0;
}

.igahp-navbar-right li a {
    color: #121212;
}

.igahp-navbar-menu li {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.navbar-selected-line {
    animation-direction: alternate;
    width: 50%;
    border-radius: 10px;
    height: 4px;
    background-color: #36623f;
    display: none;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.igahp-navbar-item.selected .navbar-selected-line {
    display: unset;
}

.igahp-navbar-item.selected a {
    color: #36623f;
}

.igahp-navbar-icon {
    display: none;
}

.igahp-navbar-button {
    display: block;
}

.igahp-navbar-button.hidden,
.hide-btn-login {
    display: none;
}

.btn-container {
    max-width: fit-content;
    margin-top: 24px;
}

.btn-unduh-file {
    border-radius: 999px;
    background: #f5f4f1;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;
    color: #36623f;
    gap: 4px;
}

.btn-icon-only {
    display: flex;
    padding: 14px 14px;
    /* justify-content: center;
    align-items: center; */
    gap: 8px;
    border-radius: 99px;
    border: 1.5px solid #fff;
    /* color: #6DC57E; */
    background: #FFF;
    cursor: pointer;
}

.btn-green-border {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 99px;
    /*border: 1.5px solid #6DC57E;
    */color: #6DC57E;
    /*background: #FFF;
    */cursor: pointer;
    transition-duration: 0.4s;
    
}

.btn-green-border:hover {
    background-color: #f4f3f1;
}

.btn-green-border-pressed {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 99px;
    border: 1.5px solid #36623F;
    color: #36623F;
    /* background: #FFF; */
    cursor: pointer;
}

.btn-secondary-white {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 99px;
    color: #36623F;
    background: #FFF;
    cursor: pointer;
    }

.btn-green-border img,
.btn-default img {
    margin: 0;
}

.btn-green-solid {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 99px;
    color: #FFF;
    background: #6DC57E;
    cursor: pointer;
}

.btn-green-solid-pressed {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 99px;
    color: #FFF;
    border: none;
    background: #36623F;
    cursor: pointer;
    transition-duration: 0.4s;
}

.btn-green-solid-pressed:hover {
    opacity: 90%;
}

.btn-default {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    color: #6DC57E;
    background: #FFF;
}

.bg-rounded-neutral-20 {
    position: absolute;
    border-radius: 9999px;
    width: 44px;
    height: 44px;
    background-color: #f5f5f5;
}

.section-hero-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-background-hero-image img {
    margin: 0;
}

.selected-line {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 8px;
}

.mid-line {
    width: 50%;
    height: 4px;
    border-radius: 8px;
    background-color: #1C5BB8;
}

.left-line,
.right-line {
    width: 36px;
    height: 1px;
    background-color: #1C5BB8;
    display: flex;
}

.left-line {
    margin-right: 1px;
}

.right-line {
    margin-left: 1px;
}

.wrapper-filter {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1.5rem;
}

.wrapper-filter img {
    z-index: 50;
}

.wrapper-form-search {
    display: flex;
    align-items: center;
    position: absolute;
    width: 70%;
}

.wrapper-search {
    display: flex;
    justify-content: center;
    margin-top: 42px;
}

.form-search {
    width: 100%;
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 6px;
    border: 1px solid #E0E0E0;
}

.wrapper-form-search .btn-search {
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    position: absolute;
    gap: 8px;
    right: 6%;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    background: #6DC57E;
    border: none;
    max-height: 2.25rem;
    cursor: pointer;
}

.wrapper-house-list {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 1.5rem;
    margin-top: 5rem;
    margin-left: 10rem;
    margin-right: 10rem;
}

.list-grid-house {
    display: flex;
    /*flex-direction: column;
    */align-items: flex-start;
    border-radius: 12px;
    /* background: #F5f4f1; */
    /*box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    */
    border: 1px solid #DDDDDD;
}

.wrapper-house-type {
    padding: 2px 8px;
    border-radius: 6px 0px;
    background: #1C5BB8;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: max-content;
}

.wrapper-house-subsidi {
    padding: 10px 8px;
    border-radius: 99px 99px;
    background: #36623F;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    width: max-content;
    margin: 0.5rem;
}

.house-photo {
    width: 400px;
    /* height: 250px; */
}

.house-photo img {
    object-fit: cover;
    border-radius: 8px;
    width: 100%;
    height: 220px;
}

.total-house {
    margin-top: 1rem;
    /* margin-left: 0.75rem; */
    display: flex;
    padding: 0.25rem 0.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #F8FFF6;
    height: 4%;
}

.total-house .subsidi-text {
    color: #6DC57E;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
}

.wrapper-house-detail {
    margin: 0.75rem 0.25rem;
}

.wrapper-detail-tipe-unit {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1rem;
}

.list-grid-house p {
    margin: 0;
}

.wrapper-developer {
    margin-left: 0.25rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    align-items: center;
}

.wrapper-page-button {
    display: flex;
    flex-direction: row;
    gap: 12px;
    align-items: center;
    justify-content: center;
}

.arrow-button {
    padding: 6px;
    background-color: #FFF;
    border: none;
    cursor: pointer;
}

.arrow-button:disabled {
    cursor: default;
}

.arrow-button svg path {
    fill: #6DC57E;
}

.arrow-button:disabled svg path {
    fill: #E0E0E0;
}

.page-button {
    padding: 10px 14px;
    border-radius: 4px;
    color: #9e9e9e;
    border: 1px solid #f5f5f5;
    background-color: #fff;
    display: flex;
    cursor: pointer;
}

.page-button:disabled {
    color: #FFF;
    border: none;
    background-color: #6DC57E;
    cursor: default;
}

.hero-image {
    display: flex;
    position: absolute;
    align-items: center;
    right: 0;
    justify-content: center;
    width: 50%;
}

.content-background-hero-image img {
    margin: 0;
}

.content-background-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bg-rectangle-blue,
.bg-rectangle-dark-blue {
    width: 231px;
    height: 81px;
    position: absolute;
}

.bg-rectangle-blue {
    background: rgba(28, 91, 184, 0.80);
    bottom: 56px;
    right: 164px;
    z-index: 50;
}

.bg-rectangle-dark-blue {
    border-top: 2px solid #1C5BB8;
    border-left: 2px solid #1C5BB8;
    background: #091E3D;
    bottom: 18px;
    right: 0;
}

.wrapper-about-us a {
    text-decoration: none;
    margin-top: 8px;
}

.wrapper-tab {
    display: flex;
    justify-content: center;
}

.tab {
    padding: 32px 40px;
    background-color: #f5f5f5;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.176px;
    color: #616161;
}

.tab-selected {
    color: #6DC57E;
    background-color: #FFF;
}

.content-about-us,
.content-our-team {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 80px;
    margin-left: 250px;
    margin-right: 250px;
    text-align: center;
}

.content-about-us h3 {
    font-family: "Inter";
    font-weight: 700;
    letter-spacing: -0.456px;
}

.content-visi-misi {
    margin: 40px;
}

.content-visi,
.content-misi {
    display: flex;
    padding-top: 40px;
}

.image-visi,
.image-misi {
    width: 52.5%;
    height: auto;
}

.image-visi img,
.image-misi img {
    height: 100%;
}

.text-visi,
.text-misi {
    width: 50%;
    display: flex;
    align-items: center;
    background-color: #F8FFF6;
    padding: 12px;
}

.text-visi,
.image-misi {
    margin-left: 24px;
}

.title-our-team {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title-our-team h3 {
    color: #000;
    font-family: "Inter";
    font-weight: 700;
    letter-spacing: -0.456px;
}

.team-photo {
    display: flex;
    flex-direction: row;
    gap: 10%;
    margin-top: 16px;
}

.team-photo .wrapper-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper-profile .profile-photo {
    max-width: 300px;
}

.wrapper-profile p {
    margin: 0;
}

.wrapper-profile .profile-name {
    margin-top: 16px;
}

.wrapper-profile .profile-job-position {
    margin-top: 8px;
}

.content-background-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.content-hero-image-left {
    display: flex;
    position: absolute;
    align-items: center;
    left: 0;
    justify-content: center;
    width: 50%;
    margin-top: 1rem;
}

.landing-page-title a {
    display: flex;
    width: auto;
    margin: 2.5rem 0;
}

.landing-page-title p {
    margin: 1.5rem 0;
}

.wrapper-green-house {
    display: flex;
    align-items: center;
    margin-top: 5rem;
    margin-left: 2.5rem;
    gap: 2rem;
    padding-bottom: 1rem;
}

/* .wrapper-image-green-house #image_green_house {
    max-width: 80%;
} */

.wrapper-image-green-house,
.wrapper-description-green-house {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.wrapper-description-green-house {
    display: flex;
    flex-direction: column;
    position: relative;
    background: #F1F0EB;
    border-radius: 24px 0px 0px 24px;
}

.wrapper-description-green-house .trees-illustration-image {
    position: absolute;
    right: 2.5rem;
    bottom: 0;
}

.description-green-house-text {
    padding: 2.5rem 2.5rem 2.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 20;
}

.description-green-house-text .btn-green-solid-pressed {
    width: fit-content;
}

.description-green-house-text a:hover {
    color: #fff;
}

.green-house-certificated-background {
    position: absolute;
    z-index: 0;
    width: 100%;
}

.green-house-certificated #bg-green-house-certificated {
    width: 90%;
}

.wrapper-ecosystem-title .selected-line,
.wrapper-ecosystem-title .left-line,
.wrapper-ecosystem-title .right-line,
.wrapper-ecosystem-title .mid-line,
.importance-green-house-title .selected-line,
.importance-green-house-title .left-line,
.importance-green-house-title .right-line,
.importance-green-house-title .mid-line,
.image-qr-download-title .selected-line,
.image-qr-download-title .left-line,
.image-qr-download-title .right-line,
.image-qr-download-title .mid-line,
.wrapper-our-activity-title .selected-line,
.wrapper-our-activity-title .left-line,
.wrapper-our-activity-title .right-line,
.wrapper-our-activity-title .mid-line {
    width: 100%;
}

.green-house-certificated-title .selected-line,
.green-house-certificated-title .left-line,
.green-house-certificated-title .right-line,
.green-house-certificated-title .mid-line {
    width: 75%;
}

.green-house-certificated, .flat-house-certificated {
    padding: 5rem 2.5rem;
    gap: 12rem;
    align-items: center;
    display: flex;
    position: relative;
    background-color: #F1F0EB;
}

.flat-house-certificated {
    margin: 0 0;
}

.green-house-certificated-title {
    width: fit-content;
    align-items: center;
    display: flex;
    flex-direction: column;

    /* Font */
    font-family: "Inter";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.88px;
    color: #000;
    line-height: 3rem;
}

.green-house-certificated-content {
    display: flex;
    width: 40%;
    gap: 8px;
    flex-direction: column;
    z-index: 10;
}

.green-house-certificated .btn-green-solid-pressed, .flat-house-certificated .btn-green-solid-pressed {
    margin-top: 2rem;
    width: fit-content;
}

.body-home {
    background: #f5f4f1;
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
}

.body-home-content-igahpdetail {
    background: #D3EED8;
}

.body-home-content-panduan {
    background: #B7C4DD;
}

.body-home-content-sertifikasi {
    background: #C3E9E3;
}

.body-home-content-product {
    background: #D3EED8;
}

.body-home-content-aplikasi {
    background: #D3EED8;
}

.body-home-content-regulasi {
    background: #F8EBD7;
}

.section-home-content {
    display: flex;
    /* width: 100%;
    height: 100%; */
    padding: 8rem 2.5rem 0rem 2.5rem;
    gap: 3rem;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
}

.section-headlines-header-text {
    width: 100%;
    max-width: 1680px;
    margin: auto;
    display: flex;
    padding: 8rem 2.5rem 8rem 2.5rem;
    gap: 3rem;
    justify-content: space-between;
    align-items: flex-start;
}

.wrapper-home-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.wrapper-label-headlines {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1.5rem;
}

.section-img-contribute {
    display: flex;
    /* width: 100%;
    height: 100%; */
    padding: 2.5rem 2.5rem 1rem 2.5rem;
}

.wrapper-home-content img {
    margin-top: 2rem;
    align-items: start;
    justify-content: center;
}

.wrapper-igahp-contribute {
    margin-top: 3.75rem;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 1rem;
    z-index: 50;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 0;
}

.igahp-contribute {
    gap: 2.5rem;
    display: flex;
    align-items: center;
}

.igahp-contribute img {
    max-height: 50px;
    -webkit-filter: grayscale(1);
}

.wrapper-total-house {
    display: flex;
    margin-top: 7rem;
}

.wrapper-house-information {
    width: 40%;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.wrapper-house-information .btn-green-solid-pressed {
    /* width: fit-content; */
}

.wrapper-button-house-information {
    display: flex;
    gap: 0.75rem;
}

.wrapper-total-rumah-subsidi,
.wrapper-total-rumah-subsidi-hijau {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.wrapper-total-rumah-subsidi p,
.wrapper-total-rumah-subsidi-hijau p {
    margin: 0;
}

.house-total {
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.earth-image {
    margin-top: 1.5rem;
}

.wrapper-bg-sillhouette {
    position: absolute;
    width: 100%;
}

.wrapper-bg-ellipse {
    margin-top: 22.75rem;
    display: flex;
    justify-content: center;
}

.wrapper-bg-ellipse img {
    width: 100%;
}

.wrapper-ecosystem {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.border-grey-24 {
    padding: 1.5rem 0.75rem;
    display: flex;
    flex-direction: column;
    border-radius: 1.5rem;
    border: 2px solid #DDDDDD;
    background: #FFF;
}

.wrapper-ecosystem-information {
    display: flex;
    margin-top: 27px;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* gap: 50%; */
}

.wrapper-ecosystem-information .border-grey-24 {
    width: 25rem;
}

.wrapper-ecosystem-information-title {
    display: flex;
    gap: 0.5rem;
}

.wrapper-ecosystem-information-title-text {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.wrapper-ecosystem-information-komersil,
.wrapper-ecosystem-information-subsidi {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
    align-items: center;
}

#emisi-gas-rumah-kaca-value,
#total-pohon-value {
    line-height: 2rem;
}

.bg-solid-neutral-20-rad-8 {
    display: flex;
    padding: 0.5rem 0;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: #F5F5F5;
}

.wrapper-ecosystem-information .border-grey-24 .bg-solid-neutral-20-rad-8 {
    margin-top: 0.75rem;
}

.border-grey-24 p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.super-sub {
    translate: 0 5px;
    font-size: 12px;
}

.background-white {
    background-color: #fff;
}

.section-hero-image-landing {
    display: flex;
    padding: 1.2rem 1.2rem ;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    background: #f5f4f1;
}

.section-hero-image-landing video {
    /* object-fit: cover; */
    width: 100%;
    height: 600px;
    align-self: stretch;
    border-radius: 24px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}

.section-hero-image-landing .video-container {
    position: relative;
    width: 100%;
    height: 800px;
    border-radius: 24px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    overflow: hidden;
}

.video-cover {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.play-button {
    position: absolute;
    background: rgba(255, 255, 255, 0.597);
    color: white;
    border: none;
    border-radius: 999px;
    font-size: 2rem;
    padding: 2rem 2rem;
    cursor: pointer;
    -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    -ms-border-radius: 999px;
    -o-border-radius: 999px;
}

#video-player {
    display: none;
}

.modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal.hidden {
    display: none;
}

.modal-content-video {
    position: relative;
    background: rgb(0, 0, 0);
    border-radius: 24px;
    overflow: hidden;
    width: 100%;
    max-width: 800px;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    /*background: transparent;
    */border: none;
    color: white;
    cursor: pointer;
    border-radius: 999px;
    padding-top: 4px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;

}


.section-green-house-objective {
    display: flex;
    justify-content: space-between;
    padding: 3.5rem 2.5rem 2.5rem 5.5rem;
    gap: 8rem;
    position: relative;
}

.section-green-house-objective #green-house-objective-title {
    width: 60%;
}

.section-green-house-objective #green-house-objective-description {
    width: 40%;
}

.section-green-house-objective #green-house-objective-tree {
    position: absolute;
    bottom: 0;
    left: 2.5rem;
}

.section-body {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
}

.section-body-maxpage {
    width: 100%;
    max-width: 1680px;
    margin: auto;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.body-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 100px 50px;
    position: relative;
    background: #fff;
}

.body-card-panduan {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    padding: 0px 50px;
    position: relative;
    background: #fff;
}

.body-card-katalog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    /* padding: 20px 50px; */
    position: relative;
    /* background: #fff; */
    margin-top: 3rem;
    margin-bottom: 3rem;
    /* border: 1px solid; */
    border-radius: 12px;
    border-color: #ddd;
}

.heading-text {
    max-width: 465px;
    /* min-width: 465px; */
    text-align: center;
}

.sub-label {
    height: 48px;
    text-align: center;
}

.group-card {
    align-items: stretch;
    display: flex;
    gap: 32px;
    justify-content: space-between;
    position: relative;
    /* padding: 24px 0px ; */
    width: 100%;
}

.group-card-panduan {
    display: flex;
    gap: 32px;
    margin-top: -5.5rem;
    margin-bottom: 2rem;
}

.card-product {
    align-items: flex-start;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    /* position: relative; */
    justify-content: space-between;
    background: #f5f4f1;
    width: 100%;
}

.card-panduan {
    align-items: flex-start;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    /* position: relative; */
    justify-content: space-between;
    background: #f5f4f1;
    width: 100%;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
}

.support-text {
    max-width: 430px;
    height: auto;
}

.label-product {
    align-items: flex-end;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    justify-content: space-between;
}

.label-panduan {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    justify-content: space-between;
}

.wrapper-igahp-partner {
    margin-top: 3.75rem;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 1rem;
    z-index: 50;
    align-items: center;
    justify-content: center;
    padding: 2.5rem 0;
}

.igahp-partner {
    gap: 2.5rem;
    display: flex;
}

.igahp-partner img {
    max-height: 50px;
    -webkit-filter: grayscale(1);
}

.wrapper-house-information .background-white {
    height: 5rem;
}

.border-left-bottom-green {
    border-left: 2px solid #6DC57E;
    border-bottom: 2px solid #6DC57E;
}

.description-green-house-text .border-left-bottom-green {
    width: 30%;
    height: 30%;
    position: absolute;
    left: 5.5rem;
    bottom: 3.5rem;
}

.green-house-list {
    z-index: 10;
    position: relative;
    display: flex;
    gap: 1.5rem;
    flex-direction: column;
}

.green-house-list ul {
    gap: 2.5rem;
    display: inline-flex;
    padding: 0;
}

.green-house-list li {
    display: inline-block;
}

.green-house-list .list-grid-house {
    width: 100%;
    padding: 1rem;
    gap: 2rem;
}

.wrapper-house-id {
    /* padding-left: 0.25rem;
    padding-right: 0.75rem; */
    flex: 1;
    position: relative;
    letter-spacing: -0.01em;
    line-height: 20px;
    text-align: left;
    font-size: 14px;
    text-transform: uppercase;
    color: #0D5800;
}

.importance-green-house-title {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    margin: 0 2.5rem;
    width: 30%;
    text-align: center;
}

.importance-green-house-description ul {
    gap: 1.5rem;
    display: flex;
    justify-content: center;
}

.importance-green-house-description {
    margin: 1.5rem 2.5rem 0 2.5rem;
    width: 100%;
}

.importance-green-house {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 7.5rem;
}

.importance-green-house-item {
    width: 20rem;
    height: 13rem;
    border-radius: 12px;
    border: 1px solid #6DC57E;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.importance-green-house-item #importance-green-house-item-title {
    margin: 0.25rem 0 0 0;
    text-align: center;
}

.importance-green-house-item #importance-green-house-item-description {
    margin: 0;
    text-align: center;

}

.wrapper-accepted-certificate {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5.5rem;
}

.list-accepted-certificate {
    margin-top: 2rem;
    display: flex;
    gap: 6rem;
}

.accepted-certificate-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.start-green-house-certification {
    position: relative;
    border-radius: 24px 24px 0px 0px;
    background: #6DC57E;
    display: flex;
    align-items: center;
    margin-top: 6.5rem;
}

.start-green-house-left-image,
.start-green-house-end-bottom-image {
    position: absolute;
}

.start-green-house-left-image {
    left: 0;
    top: 0;
    border-radius: 24px 24px 0px 0px;
    position: relative;
}

.start-green-house-end-bottom-image {
    right: 0;
    bottom: 0;
}

.start-green-house-title p {
    margin: 0;
}

.start-green-house-title-mobile {
    display: none;
}

.start-green-house-title h2 {
    font-weight: 600;
}

.blue-border-bottom {
    width: 40%;
    border-bottom: 3px solid #1C5BB8;
    margin-top: 0.5rem;
}

.start-green-house-certification .btn-default {
    width: 20rem;
    height: 3.5rem;
    padding: 0 1rem;
    cursor: pointer;
    margin-left: 8.5rem;
}

.start-green-house-certification .btn-default p {
    margin: 0;
}

.wrapper-green-house-testimoni {
    padding: 3rem 3.5rem 3rem 3.5rem;
    /* background: #F1F0EB; */
    margin-top: 2rem;
}

.green-house-testimoni {
    margin-top: 11.25rem;
}

.wrapper-testimoni {
    border-radius: 21.768px;
    background: #F5F5F5;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    align-items: center;
}

.wrapper-double-quotes {
    position: relative;
}

.wrapper-double-quotes img {
    position: absolute;
    right: 0;
}

.wrapper-testimoni-list {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 32px;
    margin-top: 1rem;
}

.wrapper-testimoni-item {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    position: relative;
    padding: 1.5rem;
    justify-content: space-between;
}

.wrapper-testimoni-item #green_leaf_testimoni {
    position: absolute;
    right: 0;
    bottom: 0;
}

.wrapper-testimoni-content {
    display: flex;
    align-items: start;
    /* margin: 0 1rem 1.5rem 1rem; */
}

.wrapper-user-testimoni {
    display: flex;
    gap: 1.5rem;
    align-items: flex-start;
    /* margin: 1.5rem 1.5rem 1.5rem 1.5rem; */
}

.wrapper-user-info {
    display: flex;
    flex-direction: column;
}

.wrapper-contact-us {
    display: flex;
    position: relative;
    /* border-radius: 2.5rem 2.5rem 0 0; */
    /* background-color: #374572 */
    background-color: #404040
    /* margin-top: 2.5rem; */
}

.wrapper-contact-us-content {
    margin-bottom: 10rem;
    max-width: 1680px;
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;
    /* margin: 2.5rem 5.5rem 7.5rem 2.5rem; */
    z-index: 30;
    display: flex;
    width: 100%;
    gap: 20%;
    flex-wrap: wrap;
    justify-content: space-around;
}

.wrapper-contact-us #bg-top-right-contact-us {
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: 2.5rem;
}

.wrapper-contact-us #ic-bottom-left-contact-us {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.6;
}

.wrapper-contact-us-left,
.wrapper-contact-us-right {
    width: 30%;
    padding: 1rem;
}

.wrapper-contact-us-right {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    /* align-items: start; */
}

.wrapper-contact-us-form-email {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
}

.wrapper-contact-us-form-email #ic-message-contact-us {
    position: absolute;
    margin-left: 0.75rem;
    z-index: 25;
}

.wrapper-contact-us #btn-send-contact-us {
    margin-top: 1.5rem;
}

.btn-green-solid-pressed:disabled {
    background: #C2C2C2;
    cursor: default;
}

.form-rad-12 {
    border-radius: 0.75rem;
    border: 1px solid #FFF;
    background: #FFF;
    backdrop-filter: blur(0.25rem);
    width: 100%;
    padding: 1rem;
}

.btn-rad-8-blue {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #1C5BB8;
    border: none;
    min-height: 3rem;
    cursor: pointer;
}

#form-contact-us-email {
    padding-left: 3.75rem;
}

#form-contact-us-question {
    height: 9rem;
    align-items: baseline;
    display: flex;
    border: none;
    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none;
}

.our-activity {
    margin: 7.5rem 5.5rem;
}

.wrapper-our-activity-title {
    width: fit-content;
}

.our-activity-content {
    padding: 1rem;
}

.wrapper-our-activity-list {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 0.75rem;
}

.list-our-activity {
    max-width: 25rem;
}

.green-house-objective {
    margin-top: 5rem;
    justify-content: center;
    align-items: center;
    position: relative;
    display: flex;
    flex-direction: column;
}

.green-house-objective img {
    width: 100%;
}

.wrapper-affordable-house,
.wrapper-enhancement-trash {
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper-affordable-house {
    margin-left: 2.5%;
}

.wrapper-enhancement-trash {
    margin-right: 6.5%;
}

.image-objective {
    display: flex;
    align-items: center;
    justify-content: center;
}

.left-description-objective-text,
.right-description-objective-text {
    width: 40%;
}

.left-description-objective-text {
    margin-right: 3rem;
}

.right-description-objective-text {
    margin-left: 3rem;
}

.left-title-objective-text {
    margin-right: 3rem;
}

.right-title-objective-text {
    margin-left: 3rem;
}

.vertical-line {
    width: 1px;
    height: 12rem;
    z-index: -10;
    background: #1C5BB8;
    margin: -4% 0;
}

.mobile-app-qr {
    margin: 7.5rem 5.5rem;
    display: flex;
    align-items: center;
    gap: 2.5rem;
}

.mockup-image-mobile,
.image-qr-download-mobile {
    width: 50%;
}

.image-qr-download-title .igahp-h1-bold {
    line-height: 2rem;
}

.image-qr-download-content {
    display: flex;
    gap: 2rem;
    margin-top: 1rem;
}

.wrapper-compatible-android {
    position: absolute;
    bottom: 0;
}

.scan-qr-text p {
    margin: 0;
}

.scan-qr-text {
    position: relative;
}

.bottom-navbar img {
    margin-left: 64px;
}

.big-leaf {
    margin-top: 80px;
    left: 0;
    top: 10%;
    position: absolute;
}

.three-leafs {
    right: 0;
    bottom: 0;
    position: fixed;
}

.login-container-background {
    display: flex;
    height: 100vh;
    /* font-family: Arial, sans-serif; */
    /* margin: 4rem; */
    /* flex-direction: row-reverse; */
    
  }

  /* Styling untuk bagian gambar */
  .login-banner {
    flex: 1;
    overflow: hidden;
  }

  .login-banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #FFF;
    /* opacity: 0.8; */
    border-radius: 50px;
    padding: 2rem;
    /*margin-top: 1rem;
    margin-left: 2rem;
    *//*margin-right: 2rem;
    */-webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
    -webkit-border-radius: 50px;
}

.wrapper-login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-top: 2rem;
    margin-bottom: 2rem;
    height: 100%; */
    width: 100%;
    max-width: 34rem;
    gap: 1rem;
    margin-right: 6rem;
    margin-left: 4rem;
    padding-left: 16px;
    padding-right: 16px;
    /* position: absolute; */
}


.login-logo-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    padding: 2rem;
}

.logo-title-brand {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper-form-login {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.content-login-form {
    display: flex;
    width: 100%;
    z-index: 99;
    padding: 1rem;
    flex-direction: column;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    border: 1px solid;
    border-color: #DDDDDD;
}

.igahp-title {
    color: black;
    margin-top: 16px;
}

.divider {
    background-color: #DDDDDD;
    height: 1px;
    margin-top: 8px;
    width: 100%;
}

.divider-regis {
    background-color: #DDDDDD;
    height: 1px;
    margin-top: 8px;
    width: 100%;
}

.login-title {
    color: #404040;
    margin-bottom: 2rem;
}

.icon-green {
    position: absolute;
    padding-left: 12px;
}

.input-email,
.input-password {
    display: flex;
    align-items: center;
}

.input-email {
    margin-top: 12px;
}

.input-password {
    margin-top: 24px;
}

.form-input {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 56px;
    padding-right: 12px;
    border-radius: 12px;
    width: 100%;
}

.rounded-icon {
    border-radius: 9999px;
    background-color: #f5f5f5;
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
}

.wrapper-forgot-password {
    width: 100%;
    display: flex;
    justify-content: right;
}

.forgot-password {
    color: #1C5BB8;
    display: flex;
    cursor: pointer;
}

.btn-login {
    display: flex;
    padding: 0.75rem 1rem;
    justify-content: center;
    align-items: center;
    background-color: #19AF00;
    color: #fff;
    border-radius: 8px;
    width: 100%;
    border: 0;
    cursor: pointer;
}

.divider-or {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.or-text {
    padding: 0 8px;
    color: #9E9E9E;
}

.wrapper-registration {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-registration {
    width: 100%;
    color: #6DC57E;
    text-decoration: none;
    text-align: center;
    padding: 0.75rem 1rem;
    border: 1.5px solid #6DC57E;
    background-color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

.wrapper-mobile-objective-item {
    display: none;
}

.igahp-divider {
    width: 100%;
    height: 1px;
    background-color: #DDDDDD;
}

.wrapper-coming-soon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.wrapper-coming-soon #coming-soon-illustration {
    width: 100%;
}

.wrapper-coming-soon-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 45%;
    text-align: center;
}

.wrapper-coming-soon-text #coming-soon-1 {
    margin-top: 1rem;
}

.wrapper-hero-image-detail-perumahan {
    position: relative;
    display: flex;
    flex-direction: column;
    /* margin: auto; */
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    /* width: 100%; */
    max-width: 1440px;
    /* border-radius: 12px; */
    /* padding: 2.5rem; */
    /* padding-left: 2.5rem; */
    /* padding-right: 2.5rem; */
}

/* .wrapper-hero-image-cover-depan-perumahan {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
} */

.wrapper-hero-image-detail-perumahan #image-hero-detail-rumah {
    object-fit: cover;
    position: absolute;
    border-radius: 12px;
    /* padding-left: 2.5rem;
    padding-right: 2.5rem; */
    /* -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px; */
}

.wrapper-hero-image-detail-perumahan #image-hero-blog {
    object-fit: cover;
}

.hero-image-overlay {
    background: #000;
    opacity: 0.5;
    width: 100%;
    height: 350px;
    border-radius: 12px;
}

.wrapper-history-detail-perumahan {
    display: none;
}

.wrapper-igahp-detail-house {
    position: absolute;
    margin: 3rem 0 3rem 3rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.wrapper-igahp-detail-house-title {
    display: flex;
    gap: 2rem;
    /* align-items: center; */
    /* flex-direction: column; */
}

.wrapper-igahp-detail-house-title #btn-back-detail-perumahan {
    display: flex;
}

.wrapper-certified-house {
    display: flex;
    gap: 0.5rem;
    border-radius: 999px;
    background-color: #FFF;
    padding: 0.375rem 0.5rem;
    /* -webkit-border-radius: 999px;
    -moz-border-radius: 999px;
    -ms-border-radius: 999px;
    -o-border-radius: 999px; */
}

.btn-circle-grey {
    border-radius: 50%;
    display: flex;
    border: unset;
    align-items: center;
    justify-content: center;
    padding: 6px;
    cursor: pointer;
}

.wrapper-detail-house-description {
    margin-top: 2.25rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.wrapper-detail-house-information {
    display: grid;
    grid-template-columns: 0.5fr auto 1fr;
}

.igahp-margin-left-25 {
    margin-left: 0.25rem;
}

.wrapper-igahp-detail-house #address-text {
    margin-top: 0.75rem;
}

.wrapper-house-name {
    display: flex;
    /* margin: 1rem 1rem 0 1rem; */
    width: 100%;
    align-items: center;
    position: relative;
}

.badge-certified-green {
    display: flex;
    width: max-content;
    padding: 0.25rem 0.5rem;
    border-radius: 0.75rem;
    background-color: #f1f9f2;
    gap: 0.5rem;
    right: 0;
    position: absolute;
}

.list-grid-house #house-id {
    margin: 0.5rem 1rem 0 1rem;
}

.wrapper-house-facilities {
    display: flex;
    gap: 1rem;
    /* margin: 0.5rem 1rem 0 1rem; */
}

.wrapper-total-facility {
    display: flex;
    gap: 0.25rem;
    align-items: center;
}

.wrapper-luas-bangunan-rumah {
    position: relative;
    display: flex;
    gap: 1rem;
    /* align-items: center; */
    /* margin: 0.75rem 1rem 0 1rem; */
}

.wrapper-luas-tanah {
    display: flex;
    gap: 0.25rem;
    align-items: center;
}

.list-grid-house #total-unit {
    margin: 0.75rem 1rem 1rem 1rem;
}

.wrapper-detail-perumahan-content {
       /* margin: 0 3.5rem; */
       width: 70%;
       /* flex-wrap: wrap; */
}

.wrapper-igahp-type-unit {
    /* margin-top: 2rem; */
    margin-bottom: 3rem;
}

.divider-neutral-30 {
    background-color: #DDDDDD;
    height: 1px;
    margin-top: 8px;
    width: 100%;
}

.wrapper-list-rumah-detail-perumahan {
    /* display: flex; */
    margin-top: 1.5rem;
}

.wrapper-profile-perumahan {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
    height: fit-content;
    padding: 1.5rem 1rem;
    margin: 0rem;
    gap: 1rem;
    position: sticky;
    top: 7rem;
    border-radius: 12px;
    border: 1px solid #DDDDDD;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    /* -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px; */
}

.wrapper-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.wrapper-button-profile {
    display: flex;
    gap: 0.5rem;
    width: 100%;
    margin-top: 0.5rem;
    flex-wrap: wrap;
    flex-direction: column-reverse;
}

.wrapper-button-profile .btn-green-solid-pressed,
.wrapper-button-profile .btn-green-border {
    width: 100%;
    cursor: pointer;
}

.wrapper-button-profile a {
    width: 100%;
}

.section-detail-perumahan {
    display: flex;
    /* padding: 0 2.5rem; */
    /* grid-template-columns: 2fr 1fr; */
    width: 100%;
    max-width: 1440px;
    margin: auto;
    gap: 5rem;
}

.wrapper-detail-fasilitas-perumahan {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.wrapper-info-detail-perumahan {
    display: flex;
    flex-direction: column;
    margin: 1rem 0rem;
    gap: 1.5rem;
    /* padding: 0.5rem 0.75rem; */
}

.list-info-perumahan-item {
    display: flex;
    /* flex-direction: column; */
    gap: 2rem;
}

.wrapper-sarana-prasarana {
    padding: 1rem 0.75rem;
    display: flex;
    flex-direction: column;
}

.wrapper-prasarana,
.wrapper-sarana,
.wrapper-gaya-hidup-berkelanjutan {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 0;
}

.wrapper-list-sarana-prasarana {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 0rem;
    column-gap: 1rem;
}

.wrapper-sarana-prasarana-item {
    display: flex;
    gap: 1rem;
    padding: 0.75rem 0;
    align-items: center;
}

.wrapper-siteplan-perumahan {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1.5rem 0;
    margin-top: 2.5rem;
}

.wrapper-image-siteplan-perumahan {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    position: relative;
    border: 1px solid;
    border-radius: 12px;
    overflow: hidden;
    color: #DDDDDD;
    padding: 1rem;
    gap: 1rem;
}

.wrapper-keterangan-siteplan {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
    border-radius: 8px;
    position: relative;
    padding: 0.5rem;
    border: 1px solid;
    /*background-color: #616161;*/
    /*backdrop-filter: blur(3px);*/
    /*-webkit-backdrop-filter: blur(4px);*/
}

.wrapper-warna-siteplan {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    align-items: center;
}

.wrapper-warna-siteplan .yellow-siteplan {
    width: 18px;
    height: 10px;
    background: #F4D777;
}

.wrapper-info-lokasi-perumahan {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2.5rem;
    margin-bottom: 4.25rem;
}

.wrapper-alamat-lokasi {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    /* border: 1px solid; */
}

.wrapper-contoh-rumah {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    padding-bottom: 2.5rem;
}

.wrapper-contoh-rumah-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.wrapper-contoh-rumah-gambar #contoh-rumah {
    width: 65%;
}

.wrapper-contoh-rumah-gambar {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.tooltip-dot {
    backdrop-filter: blur(0.25rem);
    padding: 5px;
    border-radius: 99%;
    width: max-content;
}

.white-dot-14 {
    width: 14px;
    height: 14px;
    border-radius: 99%;
    background: #fff;
}

#tooltip-struktur-atap {
    top: 5%;
}

#tooltip-dinding {
    top: 37%;
    left: 53%;
}

#tooltip-kusen-ventilasi {
    top: 45%;
    left: 40%;
}

#tooltip-material-bangunan {
    top: 77%;
    left: 60%;
}

#tooltip-struktur-pondasi {
    top: 88%;
    left: 33%;
}

#tooltip-dekorasi {
    top: 88%;
    left: 72%;
}

#tooltip-furniture {
    top: 86%;
    left: 54%;
}

#tooltip-ledeng-saniter {
    top: 24%;
    left: 50%;
}

.wrapper-katalog-material {
    display: flex;
    margin-top: 1rem;
    gap: 2rem;
}

.wrapper-katalog-material-depan {
    display: flex;
    margin-top: 1rem;
    gap: 5rem;
    align-items: flex-start;
}

.wrapper-konten-katalog {
    padding: 2.5rem 5.5rem;
}
.wrapper-konten-katalog-depan {
    /* padding: 2.5rem 5.5rem; */
    width: 100%;
    margin-top: 0rem;
    margin-bottom: 3rem;
    margin-left: auto;
    margin-right: auto;
}

.wrapper-konten-katalog-title {
    display: flex;
    align-items: center;
}

.konten-katalog-title-text {
    font-family: "Inter";
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -0.456px;
    color: #000;
}

.wrapper-choose-category-mobile {
    display: none;
}


.kategori-katalog-item {
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.kategori-katalog-item.selected {
    color: #26623f;
    background-color: #FFF;
    cursor: unset;
    border-radius: 8px;
    /* -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px; */
}

.kategori-katalog-item.selected .kategori-katalog-title svg path {
    fill: #6DC57E;
}

.kategori-katalog-title {
    display: grid;
    grid-template-columns: 1fr 0fr;
    align-items: center;
    justify-content: center;
}

.wrapper-kategori-katalog {
    width: 25%;
}

.wrapper-katalog-list-material {
    width: 75%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 1.5rem;
    row-gap: 1.5rem;
}

.wrapper-kategori-katalog .divider-neutral-30 {
    margin: 0;
}

.wrapper-material-item {
    padding: 1rem;
    border-radius: 12px;
    border: 1px solid #d3d3d3;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wrapper-material-item-katalog {
    padding: 1rem;
    border-radius: 12px;
    border: 1px solid #dddddd;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
}

.wrapper-tooltip {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.wrapper-tooltip:hover .tooltip-dot .white-dot-14 {
    background: #6DC57E;
    border: 1px solid #fff;
}

.wrapper-tooltip #info-material {
    visibility: hidden;
    top: 70%;
    left: 80%;
}

.wrapper-tooltip:hover #info-material {
    visibility: visible;
}

.tooltip-info-material {
    padding: 1rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 12px;
    position: absolute;
    width: max-content;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    z-index: 20;
}

.wrapper-detail-info-material {
    min-width: 150px;
    text-transform: uppercase;
}

.wrapper-detail-katalog {
    margin: 7rem 5.5rem 0 5.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.wrapper-history {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.btn-circle-green {
    border-radius: 99%;
    background: #DEF0DB;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    cursor: pointer;
}

.wrapper-list-history {
    display: flex;
    gap: 0.25rem;
}

.history-back-mobile {
    display: none;
}

.wrapper-detail-katalog-konten {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.wrapper-image-material-detail-katalog,
.wrapper-deskripsi-material-detail-katalog {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.wrapper-image-material-detail-katalog-item img {
    width: 60%;
}

.wrapper-list-image-material-detail-katalog img {
    width: 100%;
}

.wrapper-image-material-detail-katalog {
    gap: 1rem;
}

.wrapper-list-image-material-detail-katalog {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2rem;
}

.wrapper-image-material-detail-katalog-item {
    border: 1px solid #DDDDDD;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wrapper-deskripsi-material-detail-katalog {
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    border-radius: 8px;
    height: max-content;
    gap: 0.5rem;
}

.wrapper-deskripsi-material-detail-katalog .divider-neutral-30 {
    display: none;
}

.deskripsi-material-title {
    display: flex;
    position: relative;
    align-items: center;
}

.wrapper-detail-material-katalog {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.detail-material-item {
    display: grid;
    grid-template-columns: 2fr 0.1fr 2fr;
}

.wrapper-detail-deskripsi-text {
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.wrapper-list-material-detail-katalog {
    margin: 5rem 5.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.wrapper-detail-katalog-list-material {
    display: flex;
    overflow: auto;
    gap: 1.5rem;
}

.wrapper-detail-katalog-list-material .wrapper-material-item {
    width: max-content;
}

.wrapper-igahp-register {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 2rem 0; */
    width: 100%;
    max-width: 1440px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.igahp-register {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* margin-left: 2.5rem;
    margin-right: 2.5rem; */
    padding: 2rem 2rem;
}

.igahp-list-role-register {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 2.5rem;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.igahp-list-role-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: center;
    border-radius: 8px;
    border: 2px solid #DDDDDD;
    padding: 1.5rem 0.75rem;
    cursor: default;
    text-align: center;
}

.igahp-list-role-item.active {
    border: 2px solid #6DC57E;
}

.igahp-list-role-item.active svg path {
    fill: #6DC57E;
}

.igahp-form-register {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2.5rem;
    row-gap: 1.5rem;
    width: 100%;
}

.form-border-rad-12 {
    border-radius: 8px;
    border: 1px solid #9E9E9E;
    background: #FFF;
    backdrop-filter: blur(0.25rem);
    padding: 1rem;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.form-border-rad-12:disabled {
    background: #F5F5F5;

}

.igahp-form-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.igahp-form-item#location-form {
    padding: 0 1.5rem 1.5rem 1.5rem;
}

.igahp-form-btn-register {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 40%;
}

.igahp-form-btn-register .btn-green-border {
    border-radius: 8px;
    padding: 0.75rem 1rem;
}

.divider-register {
    display: flex;
    gap: 0.5rem;
}

.already-registered-text {
    width: 100%;
    text-align: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

.wrapper-hero-image-aplikasi {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wrapper-background-hero-image-aplikasi {
    position: relative;
    width: 100%;
}

.wrapper-background-hero-image-aplikasi #background-hero-image-aplikasi {
    position: relative;
    left: 0;
    z-index: 10;
    width: 80%;
}

.wrapper-background-hero-image-aplikasi #hero-image-aplikasi {
    position: absolute;
    right: 0;
    z-index: 11;
    height: 99%;
    width: 50%;
}

.wrapper-hero-text-aplikasi {
    position: absolute;
    z-index: 20;
    margin-left: 5.5rem;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}

.wrapper-hero-image-aplikasi #green-leaf-aplikasi {
    position: absolute;
    bottom: 0.6%;
    left: 0;
    z-index: 20;
}

.wrapper-download-aplikasi {
    /* background: #F8FFF6; */
    position: relative;
    margin-top: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 4rem;
    margin-right: 4rem;
}

/* .background-green-bottom-right-rounded {
    background: #19AF00;
    width: 60%;
    height: 400px;
    border-radius: 0 0 217px 0;
    position: relative;
} */

.wrapper-qr-code-aplikasi {
    width: 40%;
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* padding-left: 5.5rem; */
    z-index: 10;
}

.wrapper-qr-code-aplikasi #igahp-mobile-qr {
    border-radius: 12px;
}

.download-panduan-penggunaan {
    cursor: pointer;
    background: #FFF;
    border-radius: 6px;
    padding: 0.25rem 1rem;
    display: flex;
    gap: 0.5rem;
    width: fit-content;
    border: 1px solid #6DC57E;
}

.wrapper-image-contoh-aplikasi {
    width: 60%;
    display: flex;
    position: relative;
    gap: 1.5rem;
    /* position: absolute; */
    /* right: 4%; */
    z-index: 20;
    bottom: 0;
    align-items: end;
    justify-content: flex-end;
    /* padding-right: 5.5rem; */
}

.wrapper-image-contoh-aplikasi img {
    height: fit-content;
}

.wrapper-keunggulan-aplikasi {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 15%;
    margin-top: 5rem;
}

.keunggulan-aplikasi {
    padding: 5rem;
    width: fit-content;
    display: flex;
    /* gap: 2rem; */
    width: 100%;
}

.tab-image-aplikasi {
    width: 50%;
    display: flex;
   justify-content: center;
}

.list-keunggulan-aplikasi-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    width: 100%;
}

.list-keunggulan-aplikasi-title {
    color: #404040;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.142px;
    /* margin: 0.5rem 0.75rem 0 0.75rem; */
    width: fit-content;
    cursor: pointer;
    position: relative;
}

.list-keunggulan-aplikasi-description {
    color: #616161;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.084px;
    /* margin: 0.5rem 0.75rem 0 0.75rem; */
    display: none;
    width: fit-content;
    position: relative;
}

.list-keunggulan-aplikasi-item #chevron-aplikasi {
    position: absolute;
    top: 0;
    right: 0;
    /* margin: 0.5rem 0.75rem 0 0.75rem; */
}

.wrapper-list-keunggulan-aplikasi {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.wrapper-deskripsi-keunggulan-aplikasi {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 60%;
    justify-content: start;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.list-keunggulan-aplikasi-item.active {
    /* border-radius: 8px; */
    /*box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
     */
     border-bottom: 1px solid;
     border-color: #d3d3d3;
}

.list-keunggulan-aplikasi-item.active .list-keunggulan-aplikasi-title {
    color: #19AF00;
}

.list-keunggulan-aplikasi-item.active #chevron-aplikasi {
    content: url(/public/images/ic_chevron_up.svg);
}

.list-keunggulan-aplikasi-item.active .list-keunggulan-aplikasi-description {
    display: block;
}

.list-keunggulan-aplikasi-item.active .divider-neutral-30 {
    visibility: hidden;
}

.keunggulan-aplikasi #image-keunggulan-aplikasi {
    width: 100%;
    height: 500px;
}

.keunggulan-aplikasi #image-keunggulan-aplikasi-mobile {
    display: none;
}

.wrapper-blog-title {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 2rem;
    width: 20%;
    position: absolute;
    background: #FFF;
    border-radius: 1.5rem 1.5rem 0 0;
    bottom: 0;
    margin-left: 5.5rem;
}

.wrapper-newest-news {
    padding: 5rem 5.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.wrapper-newest-news-content {
    border-radius: 1rem;
    border: 1px solid #DDDDDD;
    padding: 1.5rem;
    display: flex;
    gap: 2rem;
}

.wrapper-newest-news-content #image-berita-terbaru {
    width: 50%;
}

.newest-news-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem 0;
    position: relative;
}

.newest-news-date {
    position: relative;
    bottom: 0;
    display: flex;
    margin-top: 1rem;
    align-items: center;
}

.chevron-date-news {
    width: 2rem;
    height: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #CAF9C3;
    border-radius: 99%;
    position: absolute;
    right: 0;
}

.wrapper-news {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2.5rem 5.5rem;
}

.wrapper-news-title {
    position: relative;
    display: flex;
    align-items: center;
}

.wrapper-news-category-list {
    display: flex;
    gap: 2rem;
    position: absolute;
    right: 0;
}

.news-category-item {
    font-family: "Inter";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.252px;
    color: #9E9E9E;
}

.news-category-item.active {
    color: #404040;
}

.wrapper-news-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 2rem;
    column-gap: 2rem;
    max-height: max-content;
}

.wrapper-news-content a {
    color: #404040;
}

.news-content-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.wrapper-image-news {
    position: relative;
}

.wrapper-image-news #image-list-news {
    border-radius: 1.5rem 1.5rem 0 0;
    object-fit: cover;
}

.wrapper-category-text-news {
    padding: 0.5rem 2rem;
    border-radius: 0.5rem 0.5rem 0 0;
    position: absolute;
    bottom: 0;
    right: 1rem;
    background: #FFF;
    text-transform: uppercase;
}

.wrapper-content-news {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 2rem;
}

.wrapper-page-news {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
}

.page-news-list {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}

.page-news-item {
    border: 1px solid #C0ECBA;
    border-radius: 99%;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.176px;
    color: #0D5800;
    background: #FFF
}

.page-news-item.active {
    background: #CAF9C3;
    border: 0;
}

.page-news-prev,
.page-news-next {
    border: 1px solid #C0ECBA;
    border-radius: 99%;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: "Inter";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.176px;
    color: #0D5800;
}

.page-news-prev svg path,
.page-news-next svg path {
    fill: #0D5800;
}

.page-news-prev.disabled svg path,
.page-news-next.disabled svg path {
    fill: #E0E0E0;
}

.wrapper-detail-news-post {
    display: flex;
    gap: 7.5rem;
    padding: 5rem 5.5rem;
    margin-top: 2rem;
    position: relative;
}

.wrapper-detail-news-post-content {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
    position: relative;
}

.wrapper-detail-news-sidebar {
    width: 30%;
    height: fit-content;
    position: sticky;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    top: 5.5rem;
}

.detail-news-post-content,
.detail-news-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.wrapper-detail-news-tags {
    position: relative;
    display: flex;
    gap: 1.5rem;
    align-items: center;
}

.wrapper-list-detail-news-tags {
    display: flex;
    gap: 1.5rem;
}

.list-tags-item {
    border-radius: 6px;
    border: 1px solid #404040;
    padding: 0.5rem 1.5rem;

    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.176px;
}

.wrapper-share-news-social-media {
    display: flex;
    gap: 1.5rem;
    position: absolute;
    right: 0;
}

.wrapper-search-detail-news {
    background: #FAFAFA;
    padding: 1rem 2rem;
    gap: 1rem;
    display: flex;
    border-radius: 8px;
    position: relative;
    align-items: center;
}

.wrapper-search-detail-news input {
    border: none;
    background: none;
}

.wrapper-search-detail-news #ic_search_detail_news {
    position: absolute;
    right: 2rem;
}

.wrapper-category-detail-news {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background: #FAFAFA;
    border-radius: 8px;
}

.wrapper-list-category-detail-news {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.wrapper-list-category-detail-news .divider-neutral-30 {
    margin-top: 0;
}

.wrapper-berita-detail-news {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 5rem 5.5rem;
}


.wrapper-new-landing {
    position: relative;
    overflow: hidden;
}

.wrapper-new-landing-title {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    position: absolute;
    margin-top: 2.5rem;
    z-index: 20;
}

.wrapper-new-landing-description {
    padding: 0.75rem;
    color: #6DC57E;
    border-radius: 0.75rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
}

.wrapper-new-landing #image-fence {
    position: absolute;
    left: 0;
    bottom: 17.8%;
    width: 15%;
}

.wrapper-new-landing #new-landing {
    /* max-height: 99.3vh; */
    /* width: 100vw; */
    /* object-fit: cover;
    object-position: bottom; */
}

.wrapper-new-landing #corn {
    position: absolute;
    left: 4.5%;
    bottom: 22.5%;
    width: 18%;
}

.wrapper-new-landing #coconut-tree {
    position: absolute;
    left: 25%;
    bottom: 29%;
    width: 15%;
}

.wrapper-new-landing #house-landing {
    position: absolute;
    left: 38%;
    bottom: 17.5%;
    width: 20%;
}

.wrapper-new-landing #bicycle {
    position: absolute;
    left: 21%;
    bottom: 17%;
    width: 7%;
}

.wrapper-new-landing #trash-can {
    position: absolute;
    right: 37.5%;
    bottom: 17.8%;
    width: 4%;
}

.wrapper-new-landing #chair-table {
    position: absolute;
    right: 46%;
    bottom: 19%;
    width: 4%;
}

.wrapper-new-landing #bamboo {
    position: absolute;
    right: 29%;
    bottom: 29.2%;
    width: 11%;
}

.wrapper-new-landing #bank {
    position: absolute;
    right: 7%;
    bottom: 18%;
    width: 23.5%;
}

.wrapper-new-landing #fashion {
    position: absolute;
    right: 3%;
    bottom: 17.5%;
    width: 3%;
}

.wrapper-new-landing #fish {
    position: absolute;
    right: 47%;
    bottom: 2%;
    width: 10%;
}

.wrapper-new-landing #pavling-block-landing {
    position: absolute;
    bottom: 16%;
    width: 100%;
}

.wrapper-image-landing:hover #image-trash-can {
    content: url(/public/images/newlanding/ic_trash_can_hover.png);
}

.wrapper-image-landing:hover #image-corn {
    content: url(/public/images/newlanding/ic_corn_hover.png);
}

.wrapper-image-landing:hover #image-coconut-tree {
    content: url(/public/images/newlanding/ic_coconut_tree_hover.png);
}

.wrapper-image-landing:hover #image-house {
    content: url(/public/images/newlanding/ic_house_landing_hover.png);
}

.wrapper-image-landing:hover #image-bicycle {
    content: url(/public/images/newlanding/ic_bicycle_hover.png);
}

.wrapper-image-landing:hover #image-chair-table {
    content: url(/public/images/newlanding/ic_chair_table_hover.png);
}

.wrapper-image-landing:hover #image-bamboo {
    content: url(/public/images/newlanding/ic_bamboo_hover.png);
}

.wrapper-image-landing:hover #image-bank {
    content: url(/public/images/newlanding/ic_lembaga_keuangan_hover.png);
}

.wrapper-image-landing:hover #image-fashion {
    content: url(/public/images/newlanding/ic_fashion_hover.png);
}

.wrapper-image-landing:hover #image-fish {
    content: url(/public/images/newlanding/ic_fish_hover.png);
}

.wrapper-image-landing:hover #image-pavling-block {
    content: url(/public/images/newlanding/ic_pavling_block_landing_hover.png);
}

.wrapper-tooltip-content {
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    background: #FFF;
    border-radius: 0.75rem;
    min-width: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
    position: relative;
    min-width: 24rem;
}

.wrapper-tooltip-content-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0;
    cursor: pointer;
}

.tooltip-polygon {
    translate: 0 -0.25rem;
    margin-left: 0.25rem;
}

.wrapper-tooltip-landing {
    display: none;
    flex-direction: column;
    align-items: start;
    position: relative;
    z-index: 50;
}

.wrapper-tooltip-landing.center {
    align-items: center;
}

.wrapper-tooltip-landing.right {
    align-items: end;
}

.wrapper-tooltip-landing.right .tooltip-polygon {
    margin-right: 0.25rem;
}

.wrapper-tooltip-landing.left-polygon {
    position: absolute;
    left: 110%;
    top: 10%;
}

.wrapper-tooltip-landing.left-polygon .tooltip-polygon {
    position: absolute;
    transform: rotate(90deg);
    margin: 0;
    translate: -3rem 2rem;
}

.wrapper-image-landing:hover .wrapper-tooltip-landing {
    display: flex;
}

.wrapper-logo-landing {
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 99%;
    background: #F6F6F6;
}

.wrapper-tooltip-content .divider-neutral-30 {
    margin: 0;
}

.wrapper-modal-igahp {
    display: block;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 100;
    /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* backdrop-filter: blur(0.25rem); */
    /* Black w/ opacity */
    -webkit-animation-name: fadeIn;
    /* Fade in the background */
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s
}

.blur-background {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(0.25rem);
    position: absolute;
}

.wrapper-modal-landing-content {
    display: flex;
    flex-direction: column;
    margin: 0 20%;
    background: #FFF;
    border-radius: 1.5rem 1.5rem 0 0;
    position: fixed;
    z-index: 10;
    bottom: 0;
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s;
    gap: 1rem;
    max-height: 99%;
    overflow: auto;
    padding-bottom: 1.5rem;
    min-width: 60%;
}

.wrapper-modal-landing-content::-webkit-scrollbar {
    display: none;
}

.wrapper-modal-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: sticky;
    top: 0;
    background: #fff;
    padding: 1.5rem;
}

.wrapper-modal-header-content {
    width: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
}

.wrapper-modal-title .badge-certified-green {
    position: relative;
}

.wrapper-modal-title {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.wrapper-close-modal {
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid #000;
    border-radius: 99%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 1.5rem;
    cursor: pointer;
}

.wrapper-modal-content ul li {
    list-style: disc;
}

.wrapper-modal-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0 1.5rem;
    height: 100vh;
}

.wrapper-modal-content p {
    white-space: pre-wrap;
}

.wrapper-product-modal {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.wrapper-product-list {
    display: flex;
    gap: 1rem;
}

.wrapper-product-list .wrapper-material-item {
    width: 9.25rem;
    height: 10rem;
}

.cloud-animation {
    width: 100%;
    position: absolute;
    top: 3rem;
}

.green-bird-animation {
    position: absolute;
    top: 12rem;
    width: 8%;
    left: 0;

    animation-name: greenBirdFly;
    animation-duration: 30s;
    animation-iteration-count: infinite;
}

.red-bird-animation {
    position: absolute;
    top: 8rem;
    width: 8%;
    right: 0;
    transform: rotateY(180deg);

    animation-name: redBirdFly;
    animation-duration: 30s;
    animation-iteration-count: infinite;
}

#modal-landing .modal-content {
    border: none;
}

.wrapper-modal-minat {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.wrapper-form-minat {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2.5rem;
    row-gap: 1.5rem;
    width: 100%;
    padding: 0 1.5rem 1.5rem 1.5rem;
}

.wrapper-modal-header-minat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
    position: sticky;
    top: 0;
    background: #fff;
    padding: 1.5rem;
}

.wrapper-modal-igahp.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper-modal-minat {
    display: flex;
    flex-direction: column;
    background: #FFF;
    border-radius: 1.5rem;
    position: fixed;
    z-index: 10;
    -webkit-animation-name: slideTopIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideTopIn;
    animation-duration: 0.4s;
    gap: 1rem;
    overflow: auto;
    padding-bottom: 1.5rem;
    width: fit-content;
    min-width: 50%;
}

.wrapper-modal-minat .divider-neutral-30 {
    margin: 0;
}

.wrapper-modal-minat .btn-green-solid-pressed {
    width: fit-content;
    margin: 0 1.5rem 0 auto;
    border: 0;
}

/* Add Animation */
@-webkit-keyframes slideIn {
    from {
        bottom: -300px;
        opacity: 0
    }

    to {
        bottom: 0;
        opacity: 1
    }
}

@keyframes slideIn {
    from {
        bottom: -300px;
        opacity: 0
    }

    to {
        bottom: 0;
        opacity: 1
    }
}


@-webkit-keyframes slideTopIn {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@keyframes slideTopIn {
    from {
        top: -300px;
        opacity: 0
    }

    to {
        top: 0;
        opacity: 1
    }
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes redBirdFly {
    from {
        right: -25%;
    }

    to {
        right: 125%;
    }
}

@keyframes greenBirdFly {
    from {
        left: -25%;
    }

    to {
        left: 125%;
    }
}

/* togle language */

    .toggle-language {
    border-radius: 999px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    }

    .select-language-border {
    position: relative;
    border-radius: 999px;
    border: 1px solid #DDDDDD;
    box-sizing: border-box;
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    padding: 4px;
    font-size: 14px;
    color: #9e9e9e;
    font-family: Inter;
    height: 34px;
    }
    
    /* .select-language-border :hover {
        background-color: #9e9e9e;
    } */
    
    .selected-language {
    background-color: #f5f4f1;
    color: #36623f;
    border-radius: 999px;
    display: flex;
    flex-direction: row;
    padding: 8px;
    max-height: 24px;
    font-weight: 600;
    cursor: pointer;
    }

    .select-language-border span {
        border-radius: 999px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 8px;
        max-height: 24px;
        /* color: #36623f; */
        cursor: pointer;
        }
    


/* tab new */

.container-contoh {
    padding: 0 24px;
  }
  .tabs-contoh {
    display: flex;
    border-bottom: 1px solid #c6c6c6;
  }

  .tab-page {
    padding: 24px 0px 16px 0px;
    display: none;
  }
  .tab-page.active {
    display: block;
    /* width: 100%; */
  }

.button-tab-slider-default.active {
    border-radius: 999px;
    background-color: #f5f4f1;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    color: #36623f;
}
.button-tab-slider-default {
    border-radius: 999px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    cursor: pointer;
    position: relative;
    letter-spacing: -0.01em;
    line-height: 22px;
    font-weight: 500;
}
.group-tab-slider {
    align-self: stretch;
    border-radius: 999px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    /* padding: 4px; */
    gap: 12px;

}
.image-child {
    align-self: stretch;
    position: relative;
    border-radius: 12px 12px 0px 0px;
    max-width: 100%;
    overflow: hidden;
    height: 250px;
    flex-shrink: 0;
    object-fit: cover;
}
.image-property {
    align-self: stretch;
    background-color: #DDDDDD;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 12px 12px 0px 0px;
}
.unit-subsidi {
    flex: 1;
    position: relative;
    letter-spacing: -0.01em;
    line-height: 20px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    color: #0D5800;
}
.info-tags {
    border-radius: 6px;
    background-color: #f1f9f2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
}
.property-tags {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.perumahan-griya-asri {
    flex: 1;
    position: relative;
    letter-spacing: -0.01em;
    line-height: 22px;
    font-weight: 600;
    text-align: left;
    font-size: 16px;
    color: #121212;
    text-transform: uppercase;
}
.house-title {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}
.cikande-banten {
    flex: 1;
    position: relative;
    letter-spacing: -0.01em;
    line-height: 20px;
    text-align: left;
    font-size: 14px;
    color: #404040;
    text-transform: lowercase;
}
.house-address {
    display: flex;
    flex-direction: row;
}
.container-label-info {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
}
.developer-name {
    flex: 1;
    position: relative;
    letter-spacing: -0.01em;
    line-height: 20px;
    text-align: left;
    font-size: 14px;
    color: #404040;
    text-transform: uppercase;
}
.property-detail-information {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
}

.card-image-product {
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.card-information {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 16px 12px;
}
.card-tab-image {
    flex: 1;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px 0px 12px;
    gap: 8px;
}

.tab-content-product-certificate {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 24px;
    text-align: center;
    font-size: 16px;
    color: #404040;
    font-family: Inter;
    padding: 3rem 0rem;
}

/* Card Sertifikasi Hijau  */
    .supporting-text {
    width: 100%;
    flex: 1;
    position: relative;
    color: #121212;
    }
    .header-text {
    /* height: 63px; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
    }
    .text-button {
    position: relative;
    letter-spacing: -0.01em;
    line-height: 22px;
    font-weight: 500;
    }
    .button-secondary-text-white {
    border-radius: 999px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 20px;
    }
    .button-secondary-white {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    color: #36623f;
    }
    .content-sertifikasi-bgh {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    }
    
    /* attention text */
    .label-text-attention {
    align-self: stretch;
    position: relative;
    }
        
    .container-text-attention {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
    }

    .content-attention {
    align-self: stretch;
    border-radius: 12px;
    background-color: #f5f4f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2.5rem 0rem;
    }
    .container-attention {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    }

    .label-text-attention {
    font-size: 18px;
    font-family: inter;
    color: #ec4444;
    letter-spacing: -0.01em;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
    }

    .support-text-attention {
    width: 80%;
    position: relative;
    font-size: 16px;
    font-family: inter;
    color: #404040;
    letter-spacing: -0.01em;
    font-weight: 400;
    text-align: center;
    }

        
   /* Faq Regulasi Accordion */
   .heading-text-regulasi {
    text-align: center;
}
   .wrapper-list-faq-regulasi {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}
   .wrapper-deskripsi-faq-regulasi {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    justify-content: end;
    /* margin-bottom: 10%; */
}
.list-faq-regulasi-item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    position: relative;
    width: 100%;
}
.list-faq-regulasi-title {
    color: #121212;
    font-family: "Inter";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.176px;
    margin: 0.5rem 0rem 0rem 0rem;
    width: fit-content;
    cursor: pointer;
    position: relative;
}
.list-faq-regulasi-description {
    color: #404040;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.084px;
    margin: 0.5rem 0rem 0 0rem;
    display: none;
    width: fit-content;
    position: relative;
}
/* .list-faq-regulasi-item.active {
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
} */
.list-faq-regulasi-item.active .list-faq-regulasi-title {
    color: #36623F;
}
.list-faq-regulasi-item #chevron-aplikasi {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem 0.75rem 0 0.75rem;
}
.list-faq-regulasi-item.active #chevron-aplikasi {
    content: url(/public/images/ic_chevron_up.svg);
}

.list-faq-regulasi-item.active .list-faq-regulasi-description {
    display: block;
}

.list-faq-regulasi-item.active .divider-neutral-30 {
    visibility: true;
}








/* tablet */
@media only screen and (max-width:978px) {

    .wrapper-login-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
        height: 100%;
        width: 100%;
        max-width: 34rem;
        gap: 1rem;
        margin-right: auto;
        margin-left: auto;
        padding-left: 16px;
        padding-right: 16px;
        /* position: absolute; */
    }

    .login-banner {
        display: none;
      }

    .group-card {
        justify-content: space-between;  
        flex-direction: column;
        align-self: stretch;
    }

    footer {
        padding: 1.5rem 1rem;
    }

    .igahp-navbar-menu {
        display: none;
    }

    .igahp-navbar-icon {
        display: unset;
        margin-right: 1rem;
    }

    .igahp-navbar-content {
        position: sticky;
    }

    .igahp-navbar .igahp-navbar-left {
        margin-left: 0.5rem;
    }

    .igahp-navbar-left img {
        margin-left: 0;
    }

    .igahp-navbar-right {
        position: absolute;
        right: 0.5rem;
        display: flex;
        flex-direction: column;
    }

    .igahp-btn-portal {
        display: flex;
        gap: 0.5rem;
        color: #6DC57E;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: -0.252px;
    }

    .igahp-navbar-menu-expanded {
        display: flex;
        flex-direction: column;
    }

    .igahp-navbar-menu-expanded a {
        padding: 1.5rem 1rem;
    }

    .selected-language {
        background-color: #f3f4f1;
        color: #121212;
        border-radius: 999px;
        display: flex;
        flex-direction: row;
        padding: 8px;
        max-height: 24px;
        cursor: pointer;
    }

    .select-language-border {
        display: flex;
        border: 1px transparent;
        padding: 34px;
        font-size: 16px;
        color: #9e9e9e;
        font-family: Inter;
        justify-content: center;
        align-items: center;
    }

    .select-language-border span {
        padding: 16px;
        cursor: pointer;
    }

    .wrapper-home-content #vector_center {
        display: none;
    }

    .wrapper-home-content {
        padding: 0 0rem;
        text-align: start;
        width: 100%;
    }

    .wrapper-label-headlines {
        padding: 0 0rem;
        text-align: start;
        width: 100%;
        gap: 1.5rem;
    }

    .wrapper-total-house {
        flex-direction: column;
        align-items: center;
    }

    .wrapper-total-rumah-subsidi-hijau {
        margin-top: 1.5rem;
    }

    .section-home-content {
        position: relative;
        /* padding-top: 5rem; */
        padding-left: 20px;
        padding-right: 20px;
        /* height: 950px; */
        gap: 2.5rem;
        display: flex;
        flex-direction: column;
        /* align-items: stretch; */
    }

    .wrapper-house-information {
        width: 100%;
    }

    .section-hero-image-landing {
        display: flex;
        padding: 1.2rem 0.85rem ;
        flex-direction: column;
        align-items: flex-start;
        /* gap: 10px; */
        align-self: stretch;
        background: #f5f4f1;
    }

    .section-hero-image-landing video {
        width: 100%;
        height: 600px;
        align-self: stretch;
        border-radius: 24px;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    }

    .section-hero-image-landing .video-container {
        position: relative;
        width: 100%;
        height: 400px;
        border-radius: 24px;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
        overflow: hidden;
    }

    .section-img-contribute {
        display: flex;
        /* width: 100%;
        height: 100%; */
        padding-top: 2.5rem;
        padding-left: 1.5rem;
        padding-right: 2.5rem;
    }

    .igahp-contribute img {
        max-height: 40px;
        /* -webkit-filter: grayscale(1); */
    }

    .section-green-house-objective {
        display: flex;
        flex-direction: column;
        padding: 2.5rem 2.5rem 2.5rem 2.5rem;
        gap: 3rem;
    }

    .section-green-house-objective #green-house-objective-title {
        width: 100%;
    }

    .section-green-house-objective #green-house-objective-description {
        width: 100%;
    }

    .wrapper-bg-sillhouette #sillhouette_home {
        content: url(/public/bg_sillhouette_home_mobile.svg);
        opacity: 0.12;
        width: 100%;
    }

    .wrapper-bg-ellipse {
        position: absolute;
        bottom: 0;
        translate: 0 3rem;
    }

    .wrapper-ecosystem {
        margin-top: 1.5rem;
    }

    .wrapper-ecosystem-information {
        flex-direction: column;
        gap: 1.5rem;
    }

    .wrapper-ecosystem-information .border-grey-24 {
        width: 80%;
    }


    .wrapper-ecosystem-information-komersil,
    .wrapper-ecosystem-information-subsidi {
        width: 100%;
    }

    .background-white {
        display: none;
    }

    .wrapper-green-house {
        flex-direction: column;
        margin-left: 0;
        margin-top: 2.5rem;
        gap: 0;
    }

    .wrapper-image-green-house,
    .wrapper-description-green-house {
        width: 100%;
    }

    .wrapper-image-green-house #image_green_house {
        content: url(/public/image_green_house_mobile.png);
        max-width: 100%;
    }

    .wrapper-description-green-house {
        background: #F5F5F5;
    }

    .description-green-house-text {
        padding: 2rem 1.5rem;
        border-radius: 0;
        background: unset;
        margin-left: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        text-align: center;
        z-index: 10;
    }

    .wrapper-description-green-house .trees-illustration-image {
        z-index: 0;
    }

    .description-green-house-text #description-green-house-title {
        font-size: 20px;
    }

    .description-green-house-text #description-green-house-desc {
        font-size: 14px;
    }

    .border-left-bottom-green {
        display: none;
    }

    .wrapper-igahp-partner {
        margin-top: 58rem;
    }

    .green-house-objective #image_objective {
        content: url(/public/image_objective_new_mobile.svg);
    }

    .wrapper-mobile-objective-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-top: 1.5rem;
    }

    .green-house-objective {
        margin-top: 3rem;
    }

    .green-house-objective p {
        margin: 0;
        margin-top: 0.5rem;
    }

    .green-house-certificated {
        margin: 1.5rem 0;
        padding: 4rem 1rem;
        flex-direction: column;
        gap: 1rem;
        align-items: start;
    }

    .green-house-certificated-title {
        font-size: 32px;
    }

    .green-house-certificated-content {
        width: 100%;
        align-items: center;
        text-align: center;
    }

    .green-house-certificated-background {
        display: none;
    }

    .green-house-list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .list-grid-house {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 12px;
        /* background: #F5f4f1; */
        /* box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10); */
        border: 1px solid #DDDDDD;
    }

    .house-photo {
        width: 100%;
        /* height: 250px; */
    }
    
    .house-photo img {
        object-fit: cover;
        border-radius: 8px;
        width: 100%;
        height: 320px;
    }

    .green-house-list .list-grid-house {
        position: relative;
        width: 100%;
        gap: 2rem;
    }

    .green-house-list li:first-child {
        margin-left: 1rem;
    }

    .green-house-list li:last-child {
        margin-right: 1rem;
    }

    .green-house-certificated-title .selected-line,
    .green-house-certificated-title .left-line,
    .green-house-certificated-title .right-line,
    .green-house-certificated-title .mid-line {
        width: 100%;
    }

    .importance-green-house {
        flex-direction: column;
        margin-top: 1.5rem;
    }

    .importance-green-house-title {
        margin-top: 2.5rem;
        padding: 0 1rem;
        width: 100%;
    }

    .importance-green-house-title #importance-green-house-text-title {
        font-size: 20px;
    }

    .importance-green-house-description {
        padding: 0;
        border-radius: 0;
        width: 100%;
    }

    .importance-green-house-description ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0;
        gap: 0;
    }

    .importance-green-house-item {
        width: 80%;
        margin: 1rem;
        height: 9.25rem;
    }

    .importance-green-house-item #importance-green-house-item-description {
        display: none;
    }

    .wrapper-accepted-certificate {
        margin-top: 4rem;
    }

    .list-accepted-certificate {
        margin-top: 1.5rem;
        gap: 2rem;
    }

    .accepted-certificate-item {
        align-items: center;
    }

    .accepted-certificate-item img {
        height: 48px;
    }

    .wrapper-green-house-testimoni {
        padding: 5rem 0rem 5rem 0rem;
        /* background: #F1F0EB; */
        /* margin: 5rem; */
    }


    .wrapper-green-house-testimoni #green-house-testimoni-title {
        font-size: 20px;
        margin-left: 1rem;
    }

    .mobile-app-qr {
        flex-direction: column;
        margin: 1.5rem 1rem;
    }

    .mockup-image-mobile,
    .image-qr-download-mobile {
        width: 100%;
    }

    .image-qr-download-title {
        text-align: center;
    }

    .image-qr-download-content {
        flex-direction: column;
        gap: 1rem;
        align-items: center;
    }

    .scan-qr-text {
        text-align: center;
    }

    .wrapper-compatible-android {
        position: relative;
        margin-top: 0.75rem;
    }

    .image-qr-download {
        width: 75%;
    }

    .start-green-house-certification {
        border-radius: 0;
        margin-top: 1.5rem;
        gap: 0;
        flex-direction: column;
        padding: 1.5rem;
    }

    .start-green-house-left-image {
        display: none;
    }

    .start-green-house-end-bottom-image {
        width: 10%;
        translate: 0 0.25rem;
    }

    .start-green-house-title {
        display: none;
    }


    .start-green-house-title-mobile {
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: 0 2rem;
        align-items: center;
    }

    .blue-border-bottom {
        width: 70%;
        margin-top: -1rem;
    }

    .start-green-house-certification .btn-default {
        margin: 1rem 0 1rem 0;
    }

    .green-house-testimoni {
        margin-top: 1rem;
    }

    .wrapper-testimoni-list {
        overflow: auto;
        padding-bottom: 2rem;
    }

    .wrapper-testimoni {
        flex-direction: column;
        box-shadow: none;
        background: none;
        border-radius: 0;
    }

    .wrapper-testimoni-item {
        width: 325px;
    }

    .wrapper-testimoni-item:first-child {
        margin-left: 1rem;
    }

    .wrapper-testimoni-item:last-child {
        margin-right: 1rem;
    }

    .testimoni-source-photo {
        right: auto;
        width: 90%;
    }


    .testimoni-source-photo img {
        max-width: max-content;
        width: 100%;
        translate: 0;
        content: url(/public/image_photo_pak_cokro_mobile.png);
    }

    .wrapper-testimoni-title {
        display: grid;
        padding: 1rem;
        width: fit-content;
        grid-template-rows: 0.25fr 1fr;
        margin: 16rem 1rem 0 1rem;
        text-align: center;

        border-radius: 12px;
        background: #FFF;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    }

    .testimoni-source {
        margin-top: 0;
        grid-row-start: 1;
    }

    .wrapper-testimoni-text {
        width: 100%;
        display: grid;
        grid-template-rows: 1fr 0.25fr;
        margin-top: 0.5rem;
    }

    .wrapper-double-quotes img {
        position: relative;
        width: 15%;
    }

    .wrapper-double-quotes {
        display: flex;
        justify-content: end;
    }

    .our-activity {
        margin: 1.5rem 0;
    }

    .wrapper-our-activity-title {
        margin-left: 1rem;
    }

    .wrapper-our-activity-list {
        overflow: auto;
    }

    .list-our-activity:first-child {
        margin-left: 1rem;
    }

    .list-our-activity:last-child {
        margin-right: 1rem;
    }

    .wrapper-contact-us {
        border-radius: 0;
    }

    .wrapper-contact-us #bg-top-right-contact-us {
        border-radius: 0;
    }

    .wrapper-contact-us-content {
        margin: 2.5rem 2rem;
        flex-direction: column;
        gap: 1rem;
    }

    .wrapper-contact-us-left,
    .wrapper-contact-us-right {
        width: 100%;
    }

    .wrapper-contact-us-left #contact-us-title {
        font-size: 20px;
        text-align: center;
        line-height: 24px;
    }

    .wrapper-contact-us-right button {
        width: 100%;
        justify-content: center;
        margin-bottom: 7.5rem;
    }

    .wrapper-contact-us #ic-bottom-left-contact-us {
        width: 80px;
    }

    .wrapper-contoh-rumah {
        display: none;
    }

    .wrapper-konten-katalog {
        padding: 0;
    }

    .wrapper-konten-katalog-title {
        /* display: #6DC57E; */
        padding: 1.5rem 1rem;
        position: relative;
    }

    .konten-katalog-title-text {
        font-family: "Inter";
        font-size: 20px;
        font-weight: 600;
        letter-spacing: -0.34px;
        color: #FFF;
    }

    .wrapper-choose-category-mobile {
        display: flex;
        gap: 1rem;
        position: absolute;
        right: 1rem;
        border-radius: 0.75rem;
        background: #fff;
        padding: 0.75rem;
    }

    .wrapper-katalog-material {
        justify-content: center;
        margin: 1rem 0;
    }

    .wrapper-kategori-katalog {
        display: none;
    }

    .wrapper-katalog-list-material {
        grid-template-columns: 1fr 1fr;
        row-gap: 1rem;
        margin: 0 1rem;
        width: 100%;
    }

    .wrapper-list-history {
        display: none;
    }

    .history-back-mobile {
        display: flex;

        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.084px;
        color: #6DC57E;
    }

    .wrapper-detail-katalog {
        margin: 1rem 0;
    }

    .wrapper-detail-katalog-konten {
        flex-direction: column;
    }

    .wrapper-image-material-detail-katalog,
    .wrapper-deskripsi-material-detail-katalog {
        width: 100%;
    }

    .wrapper-list-image-material-detail-katalog {
        margin: 0 1rem;
    }

    .wrapper-history {
        margin: 0 1rem;
    }

    .wrapper-deskripsi-material-detail-katalog {
        box-shadow: none;
        padding: 0;
        padding: 0 1rem;
    }

    .wrapper-deskripsi-material-detail-katalog .divider-neutral-30 {
        display: flex;
    }

    .wrapper-list-material-detail-katalog {
        margin: 2.5rem 1rem;
    }

    .wrapper-detail-katalog-list-material {
        display: -webkit-inline-box;
        padding-bottom: 1rem;
    }

    .wrapper-detail-katalog-list-material .wrapper-material-item {
        width: 125px;
        height: 168px;
    }

    .wrapper-history-detail-perumahan {
        display: flex;
        gap: 1rem;
        align-items: center;
        margin: 1rem;
    }

    .wrapper-igahp-detail-house-title #btn-back-detail-perumahan {
        display: none;
    }

    .wrapper-igahp-detail-house {
        margin: 0 1rem;
    }

    .wrapper-igahp-detail-house-title {
        flex-direction: column;
        align-items: start;
    }

    .wrapper-detail-house-description {
        margin-top: 0.75rem;
    }

    .section-detail-perumahan {
        display: flex;
    /* padding: 0 1rem; */
    /* grid-template-columns: 2fr 1fr; */
    width: 100%;
    max-width: 1200px;
    margin: auto;
    gap: 3rem;
    }

    /* .wrapper-profile-perumahan {
        display: none;
    } */

    .wrapper-detail-perumahan-content {
          /* margin: 0 0rem; */
       width: 65%;
       flex-wrap: wrap;
    }

    .wrapper-igahp-type-unit {
        /* margin-top: 2rem; */
        margin-bottom: 3rem;
    }

    .wrapper-profile-perumahan {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 35%;
        height: -moz-fit-content;
        height: fit-content;
        padding: 1.5rem 1rem;
        margin: 0rem;
        gap: 1rem;
        position: sticky;
        top: 5.5rem;
        border-radius: 12px;
        border: 1px solid #DDDDDD;
        background: #FFFFFF;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    }

    /* .wrapper-igahp-type-unit .wrapper-list-rumah-detail-perumahan .green-house-list {
        margin: 0.5rem 0;
    }

    .wrapper-igahp-type-unit .wrapper-list-rumah-detail-perumahan .green-house-list .list-grid-house {
        width: 100%;
    } */

    /* .wrapper-igahp-type-unit .wrapper-list-rumah-detail-perumahan .green-house-list li {
        margin: 0;
    }

    .wrapper-igahp-type-unit .wrapper-list-rumah-detail-perumahan .green-house-list ul {
        gap: 1rem;
    } */

    .wrapper-info-detail-perumahan {
        padding: 0;
    }

    /* .list-info-perumahan-item {
        gap: 0.25rem;
    } */

    .wrapper-sarana-prasarana {
        padding: 0;
    }

    .wrapper-list-sarana-prasarana {
        grid-template-columns: 1fr 1fr;
    }

    .wrapper-siteplan-perumahan {
        margin-top: 1.5rem;
        padding: 0;
    }

    .wrapper-siteplan-perumahan #siteplan-cursor-description {
        display: none;
    }

    .wrapper-image-siteplan-perumahan {
        margin: 0;
    }

    .wrapper-alamat-lokasi {
        align-items: start;
    }

    .wrapper-background-hero-image-aplikasi #hero-image-aplikasi {
        content: url(/public/images/aplikasi/image_hero_aplikasi_mobile.png);
        height: 100%;
        width: fit-content;
    }

    .wrapper-background-hero-image-aplikasi #background-hero-image-aplikasi {
        width: 100%;
    }

    .wrapper-hero-image-aplikasi #green-leaf-aplikasi {
        width: 24px;
        height: 24px;
        bottom: -1.5px;
    }

    .wrapper-hero-image-aplikasi {
        height: fit-content;
    }

    .wrapper-hero-text-aplikasi {
        margin-left: 1rem;
        width: 70%;
        gap: 0.5rem;
    }

    .wrapper-hero-text-aplikasi .igahp-h3-semi-bold {
        font-size: 20px;
    }

    .wrapper-hero-text-aplikasi .igahp-m-regular {
        font-size: 12px;
    }

    .keunggulan-aplikasi #image-keunggulan-aplikasi {
        display: none;
    }

    .wrapper-keunggulan-aplikasi {
        padding: 0 1rem;
        margin-top: 2.5rem;
    }

    /* .keunggulan-aplikasi #image-keunggulan-aplikasi-mobile {
        display: none;
        margin-top: 0.5rem;
    } */

    .keunggulan-aplikasi {
        padding: 1rem;
        width: -moz-fit-content;
        width: fit-content;
        display: flex
;
        /* gap: 2rem; */
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .wrapper-deskripsi-keunggulan-aplikasi {
        width: 100%;
    }

    .list-keunggulan-aplikasi-item.active {
        border-radius: 0;
        box-shadow: none;
    }

    .list-keunggulan-aplikasi-item .divider-neutral-30 {
        margin-top: 0;
    }

    .list-keunggulan-aplikasi-item.active #image-keunggulan-aplikasi-mobile {
        display: flex;
    }

    .wrapper-download-aplikasi {
        justify-content: start;
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 5rem;
    }

    .wrapper-image-contoh-aplikasi {
        position: relative;
        gap: 1.5rem;
        right: 0;
        margin: 0 1rem;
        width: 100%;
    }

    /* .background-green-bottom-right-rounded {
        width: 100%;
    } */

    .wrapper-qr-code-aplikasi {
        margin: 3rem 0.25rem 0 0.25rem;
        align-items: center;
        text-align: center;
        width: 100%;
    }

    .wrapper-image-contoh-aplikasi img {
        width: 48%;
    }

    .keunggulan-aplikasi {
        padding: 1rem;
        width: fit-content;
        display: flex;
        /* gap: 2rem; */
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .tab-image-aplikasi {
        /* width: 50%; */
        display: flex;
        justify-content: center;
    }


    /* Register */
    .igahp-list-role-item {
        display: flex;
        flex-direction: row;
        gap: 1.5rem;
        align-items: center;
        border-radius: 8px;
        border: 2px solid #DDDDDD;
        padding: 1.5rem 0.75rem;
        cursor: default;
        text-align: center;
    }

    .text-layout-column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .igahp-register {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 1rem 1rem;
    }

    .igahp-list-role-register {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        row-gap: 1rem;
        width: 100%;
        /* padding-top: 2rem;
        padding-bottom: 2rem; */
    }

    .igahp-form-register {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        row-gap: 1.5rem;
        width: 100%;
    }

    .section-body {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .body-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding: 80px 34px;
        position: relative;
        background: #fff;
    }

    .section-headlines-header-text {
        width: 100%;
        margin: auto;
        display: flex;
        padding: 6rem 2.5rem 4rem 2.5rem;
        gap: 3rem;
        /* justify-content: space-between; */
        align-items: flex-start;
        flex-direction: column;
    }

    .tab-page.active {
        display: block;
        width: 100%;
    }

    .group-card-panduan {
        display: flex;
        gap: 24px;
        margin-top: -2.5rem;
        margin-bottom: 2rem;
    }

}

/* Mobile */
@media only screen and (max-width:548px) {


    /* Register */
    .wrapper-igahp-register {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* padding: 2rem 0; */
        /* width: 100%; */
        max-width: 1440px;
        position: relative;
        /* margin-left: 16px; */
        margin-right: 16px;
    }

    .igahp-list-role-register {
        display: flex;
        /* grid-template-columns: 1fr 1fr 1fr 1fr; */
        gap: 1rem;
        width: 100%;
        padding-top: 2rem;
        padding-bottom: 2rem;
        flex-direction: column;
    }

    .igahp-list-role-item {
        display: flex;
        /* flex-direction: column; */
        gap: 1rem;
        align-items: center;
        border-radius: 8px;
        border: 2px solid #DDDDDD;
        padding: 0.5rem 1rem;
        cursor: default;
        text-align: center;
    }

    .igahp-form-register {
        display: flex;
        flex-direction: column;
        /* grid-template-columns: 1fr 1fr; */
        column-gap: 2.5rem;
        row-gap: 1.5rem;
        width: 100%;
    }

    .igahp-form-btn-register {
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }


    /* Navbar */
    .igahp-navbar .igahp-navbar-left {
        margin-left: 0.5rem;
    }

    .section-home-content {
        display: flex;
        /* width: 100%;
        height: 100%; */
        padding-top: 4rem;
        gap: 3rem;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
    }

    .wrapper-home-content {
        padding: 0 0rem;
        text-align: start;
        width: 100%;
    }

    .wrapper-label-headlines {
        padding: 0 0rem;
        text-align: start;
        width: 100%;
    }

    .igahp-hero-title-bold {
        font-family: "Inter";
        font-size: 48px;
        font-weight: 800;
        letter-spacing: -1.36px;
        line-height: 46px;
        text-transform: uppercase;
    }
    
    .igahp-l-regular {
        font-family: "Inter";
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.252px;
    }

    .section-img-contribute {
        display: flex;
        /* width: 100%;
        height: 100%; */
        /* padding: 2rem 2rem 0rem 2rem; */
    }

    .wrapper-button-house-information {
        display: flex;
        gap: 0.75rem;
        flex-direction: column;
    }

    .igahp-contribute {
        gap: 1rem;
        display: flex;
        /* flex-direction: column; */
    }

    .igahp-contribute img {
        max-height: 30px;
        /* -webkit-filter: grayscale(1); */
    }

    .section-hero-image-landing {
        display: flex;
        padding: 1rem 0.85rem ;
        flex-direction: column;
        align-items: flex-start;
        /* gap: 10px; */
        align-self: stretch;
        background: #f5f4f1;
    }

    .section-hero-image-landing video {
        width: 100%;
        height: 400px;
        align-self: stretch;
        border-radius: 12px;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    }

    .section-hero-image-landing .video-container {
        position: relative;
        width: 100%;
        height: 300px;
        border-radius: 24px;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
        overflow: hidden;
    }

    .igahp-h2-semi-bold {
        font-family: "Inter";
        font-size: 22px;
        font-weight: 600;
        letter-spacing: -0.532px;
        line-height: 2rem;
    }

    .section-body {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    
    }

    .body-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding: 50px 25px;
        position: relative;
        background: #fff;
    }

    .body-card-panduan {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding: 0px 25px;
        position: relative;
        background: #fff;
    }

    group-card {
        padding: 24px 0px;
    }

    .igahp-highlight-semi-bold {
        font-family: "Inter";
        font-size: 28px;
        font-weight: 600;
        letter-spacing: -0.88px;
        line-height: 2.5rem;
    }

    .igahp-m-regular {
        font-family: "Inter";
        font-size: 12px;
        font-weight: 400;
        letter-spacing: -0.176px;
    }

.wrapper-profile-perumahan {
        display: none;
    }

    .group-card-panduan {
        display: flex;
        gap: 32px;
        margin-top: -2.5rem;
        margin-bottom: 2rem;
        flex-direction: column;
    }

}